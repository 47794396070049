import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

// markup
const VisionPartial = () => {
  return (
    <div className="s-vision bg-soft-blue">
      <div className="mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-center h-12 w-12 mb-4 rounded-md text-white mx-auto">
        </div>
        <h2 className="s-vision__h2 text-2l font-medium mb-5 text-black sm:mb-10 sm:text-2xl">
          <span className="o-accent-line mx-auto"></span>
          <span className="block s-vision__quote text-center">
            “The Neighbour Institute feels like a trusted guide who connects me with the tools I need to serve my neighbor even better”
          </span>
        </h2>
        <div>
          <StaticImage
            src="../../../images/journey-of-learning.png"
            className="relative s-vision__img"
            alt="Illustration of person looking through a telescope toward a mountain."
          />
        </div>
      </div>
    </div>
  );
};

export default VisionPartial;
