import React, { useState, useEffect, createRef } from 'react';
import { graphql, Link } from 'gatsby';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

import { UserContext } from '../lib/UserContext';

import Hero from './partials/index/_hero';
import Vision from './partials/index/_vision';
import CurrentFeatures from './partials/index/_currentFeatures';
import Roadmap from './partials/index/_roadmap';
import Faqs from './partials/index/_faqs';
import SignIn from './signin';

import CardGrid from '../components/CardGrid';
import Card from '../components/Card';

import '../styles/index.scss';
import '../tokens/cds-core.css';

const IndexPage = ({ data }) => {
  const mostRecent = data.mostRecent.nodes;

  return (
    <UserContext.Consumer>
      {(context) => {
        return (
          <>
            <Helmet>
              <title>
                Neighbour Institute welcomes you! • Compassion International
              </title>
            </Helmet>
            <main>
              <Hero></Hero>
              <Vision></Vision>

              <div
                id="featured-content"
                className="s-featured-content relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
              >
                <div className="absolute inset-0">
                  <div className="bg-white h-1/3 sm:h-2/3"></div>
                </div>
                <div className="relative max-w-7xl mx-auto">
                  <div className="relative flex items-center justify-center h-12 w-12 mb-4 rounded-md text-white mx-auto">
                    <StaticImage
                      src="../images/backpack.png"
                      alt="Backpack illustration"
                    />
                  </div>
                  <div className="text-center">
                    <h2 className="text-3xl tracking-tight font-display text-gray-900 sm:text-4xl">
                      Content Spotlight
                    </h2>
                    <p className="mt-3 max-w-2xl mb-10 mx-auto text-xl text-gray-500 sm:mt-4">
                      Take a tour through research, best practices, templates
                      and more
                    </p>
                  </div>

                  {context && context.user ? (
                    <CardGrid>
                      {mostRecent.map((entry) => {
                        return (
                          <Card
                            key={entry.path}
                            href={entry.path}
                            heading={entry.pageContext.title}
                            headingLevel={2}
                            metadata={entry.pageContext.metadata}
                            createdAt={entry.pageContext.createdAt}
                            readingTime={
                              entry.readingTime && entry.readingTime.minutes
                            }
                            references={
                              entry.pageContext.body &&
                              entry.pageContext.body.references
                            }
                          >
                            {entry.pageContext.introduction &&
                              entry.pageContext.introduction.plainText}
                          </Card>
                        );
                      })}
                    </CardGrid>
                  ) : (
                    <SignIn
                      className="shadow-lg m-4"
                      headingLevel={'3'}
                    ></SignIn>
                  )}
                </div>
              </div>

              <div id="contribute" className="bg-dark-blue">
                <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                  <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                    <span className="block text-lg mb-5">All Are Welcome</span>
                    <span className="block font-display">
                      Want to Contribute?
                    </span>
                  </h2>
                  <p className="mt-4 text-lg leading-6 text-indigo-200">
                    Neighbour Institute is a place to learn and share. Click the
                    button below and fill out the form as a first step in the
                    process. We look forward to adding your subject matter
                    expertise to the mix. Not sure what to write just yet? We
                    can help with that. We’re excited to learn from you!
                  </p>
                  <a
                    href="https://airtable.com/shral2kRQj9MBTBiS"
                    className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"
                  >
                    Go to Contribution Sign-up
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 pl-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>

              <CurrentFeatures></CurrentFeatures>
              <Roadmap></Roadmap>
              <Faqs></Faqs>
            </main>
          </>
        );
      }}
    </UserContext.Consumer>
  );
};

export default IndexPage;

export const indexQuery = graphql`
  query {
    mostRecent: allSitePage(limit: 6) {
      nodes {
        pageContext
        path
        readingTime {
          minutes
        }
      }
    }
  }
`;
