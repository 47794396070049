import * as React from 'react';
import { Link } from 'gatsby';

import Faq from '../../../components/Faq';

// markup
const Faqs = () => {
  return (
    <div id="faq" className="s-faq bg-soft-blue py-24">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
         <span className="o-accent-line mx-auto"></span>
          <h2 className="text-center border-none border-y-0 pb-5 text-3xl font-display text-gray-900 sm:text-4xl">
            Frequently asked questions
          </h2>

          <ul>
            <li className="border-b-2 border-gray-200 pb-5">
              <Faq>
                <span className="faq__question">
                  What is Neighbour Institute?
                </span>
                <p className="faq__answer pb-5">
                  Neighbour Institute (NI) is a self-guided learning platform designed to empower every employee, everywhere with the knowledge, skills, best practices, and insight needed to design relevant, connected, life-changing experiences for their neighbour(s).
                </p>
                <p className="faq__answer">
                  Neighbour Institute will allow us to learn with and from each other. This will empower us to move together more effectively and efficiently as one global Compassion family in knowing, loving, and connecting our neighbours to the mission of releasing children from poverty in Jesus’ name.
                </p>
              </Faq>
            </li>
            <li className="border-b-2 border-gray-200 pb-5">
              <Faq>
                <span className="faq__question">
                  What is "beta" launch?
                </span>
                <p className="faq__answer pb-5">
                Throughout FY22, a multi-functional GMBX team conducted small tests around the idea of Neighbour Institute. This began with an “Alpha” state, in which bundles of content were developed to meet the needs of multiple GPAs, helping them to better understand GMBX capabilities.
                </p>
                <p className="faq__answer pb-5">
                This approach was well received by the GPAs and has been utilized in a multitude of forums to more effectively spread understanding across multiple audiences.
                </p>
                <p className="faq__answer pb-5">
                The beta version builds from this idea of “content bundles”, with a focus on four areas of value.
                </p>
                <p className="faq__answer pb-5">
                  The Neighbour Institute beta...
                </p>
                <p className="faq__answer pb-5">
                  <ul className="list-disc list-inside">
                    <li>provides simple and secure access for all Compassion employees globally.</li>
                    <li>houses relevant content bundles that provide practical, timely skill-building value.</li>
                    <li>was designed to deliver and socialize insights and learnings seamlessly.</li>
                    <li>was built for iterative growth.</li>
                  </ul>
                </p>
              </Faq>
            </li>
            <li className="border-b-2 border-gray-200 pb-5">
              <Faq>
                <span className="faq__question">
                  Is Neighbour Institute available for all GPAs?
                </span>
                <p className="faq__answer">
                  Unlike Workday and “ONE”, Neighbour Institute is accessible to ALL Compassion employees, no matter their geographic location. This means those in the U.S., those in each of our GPAs, and those in our National Offices will all have access to the same content. This is a crucial component in empowering a unified workforce for the mission of releasing children from poverty in Jesus’ name.
                </p>
              </Faq>
            </li>
            <li className="border-b-2 border-gray-200 pb-5">
              <Faq>
                <span className="faq__question">
                  How do I contribute to Neighbour Institute?
                </span>
                <p className="faq__answer pb-5">
                  We've set up an Airtable form as a first step and to get the conversation started! <a className="text-primary font-bold" href="https://airtable.com/shral2kRQj9MBTBiS">Click here to go to the Contribution sign-up form</a>.
                </p>
                <p className="faq__answer pb-5">
                Eventually, new content will be served from Compassion’s new CMS and flow through its change management process.
                </p>
              </Faq>
            </li>
            <li className="border-b-2 border-gray-200 pb-5">
              <Faq>
                <span className="faq__question">
                  How do I explore the content in Neighbour Institute?
                </span>
                <p className="faq__answer pb-5">
                  Start by browsing our content spotlights! There you’ll find some interesting insights related to a variety of topics.
                </p>
                <p className="faq__answer pb-5">
                  Or, if you already know what you’re looking for, click Explore Content. From there, you can filter bundles of content by selecting:
                </p>
                <p className="faq__answer pb-5">
                  <ul className="list-disc list-inside">
                    <li>provides simple and secure access for all Compassion employees globally.</li>
                    <li>houses relevant content bundles that provide practical, timely skill-building value.</li>
                    <li>was designed to deliver and socialize insights and learnings seamlessly.</li>
                    <li>was built for iterative growth.</li>
                  </ul>
                </p>
                <p className="faq__answer pb-5">
                  Add or remove filters to focus on the specific type of content you’re interested in viewing. Explore related content by viewing the Similar Tags feature. And share valuable content with your teammates using the Share button.
                </p>
                <p className="faq__answer pb-5">
                  You’re all set – happy learning!
                </p>
              </Faq>
            </li>
            <li className="border-b-2 border-gray-200 pb-5">
              <Faq>
                <span className="faq__question">
                  How does Neighbour Institute relate to other platforms like “One” or MeetOurSupporters.com?
                </span>
                <p className="faq__answer pb-5">
                  <span className="pb-3 block text-black">Compassion Intranet “One”</span>
                  The intranet is accessible only to Compassion employees based in the U.S. When Intranet accessibility is updated for all GPAs and NOs, Neighbour Institute will move to live within “ONE”. 
                </p>
                <p className="faq__answer pb-5">
                 <span className="pb-3 block text-black">“One” Program Knowledge Center </span>
                 Program Knowledge Center is intended to unify programmatic understanding and messaging between supporter-facing teams in Program and Marketing. At this point, however, the hub is entirely unavailable to our GPA workforce who are based outside of the U.S.
                </p>
                <p className="faq__answer pb-5">
                  Because Neighbour Institute solves the accessibility issues faced by GPAs, it will house core Program Knowledge Center trainings focused on benefitting our GPA workforce as deeply as possible. NI will include content such as programmatic language definitions, Compassion’s Program theory of change model, and more.
                </p>
                <p className="faq__answer pb-5">
                 <span className="pb-3 block text-black">MeetOurSupporters.com </span>
                  The Meet Our Supporters website was designed to introduce users to each of our 5 individual supporter segments. It provides insights and resources needed to better know, love, and connect those supporters.
                </p>
                <p className="faq__answer pb-5">
                Neighbour Institute, on the other hand, is a one-stop shop for insights and resources focused on all Compassion neighbours – including but not limited to individual supporters and donors, church partners, workforce neighbours, and program participants.
                </p>
                <p className="faq__answer pb-5">
                 <span className="pb-3 block text-black">Digital Experience Platform (DXP) </span>
                 While Brand content bundles may be added to Neighbour Institute, the DXP will be the primary source of truth for Brand. Neighbour Institute will build the workforce expertise necessary to live out the Brand Promise.
                </p>
                <p className="faq__answer pb-5">
                 <span className="pb-3 block text-black">Workday Training </span>
                 While Workday is primarily used to assign compulsory trainings, Neighbour Institute was designed to house content and resources that build, align, and deepen workforce knowledge sets. It will also be accessible to all workforce neighbours, including GPAs and NOs.
                </p>
              </Faq>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
