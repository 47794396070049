import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Music from './_music';
import HeroSVG from './_heroSVG';

const HeroPartial = () => {
  return (
    <div className="s-hero lg:relative">
      <svg
        className="s-hero__graph absolute top-0"
        width="640"
        height="784"
        fill="none"
        viewBox="0 0 640 784"
      >
        <defs>
          <pattern
            id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
            x="118"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              className="text-gray-200"
              fill="currentColor"
            ></rect>
          </pattern>
        </defs>
        <rect
          x="118"
          width="404"
          height="784"
          fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
        ></rect>
      </svg>
      <div className="mx-auto max-w-7xl w-full pt-8 md:pt-8 lg:pt-16 pb-10 text-center lg:py-16 lg:text-left">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <div className="relative sm:h-0 md:h-0 lg:h-12  w-12 mb-4 px-1 rounded-md text-white custom-auto">
          </div>
          <div>
          <span className="o-accent-line align-left-center"></span>
            <a href="#" className="inline-flex space-x-4">
              <span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                {' '}
                Welcome!{' '}
              </span>
              <span className="inline-flex items-center text-sm font-medium text-indigo-600 space-x-1">
                <Music></Music>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h1 className="mt-5 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-4xl md:text-5xl lg:text-5xl">
            Compassion expertise and tools at your fingertips.
          </h1>
          <p className="mt-6 text-xl text-gray-700">
            <span className="text-accent font-display">Neighbor Institute</span>{' '}
            empowers every employee, everywhere, with knowledge, research and best practices to serve neighbors effectively and efficiently
          </p>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <a
                href="/explore"
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-full text-white bg-primary hover:bg-indigo-700 md:py-2 md:text-lg md:px-6"
              >
                Explore Content
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 pl-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full h-100 sm:h-100 md:h-100 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <HeroSVG></HeroSVG>
      </div>
    </div>
  );
};

export default HeroPartial;
