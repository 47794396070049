import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

const useAudio = (url) => {
  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(!playing);

  if (typeof window !== `undefined`) {
    const [audio] = useState(
      new Audio(
        'https://assets.ctfassets.net/l60f8hxsa357/6UIvQWXYegwxf99cQMOZuq/3e7cd03d195bef5ef9c07cd9e9aadf0d/wa-style.mp3'
      )
    );

    useEffect(() => {
      playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }, []);
  }

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div>
      <span onClick={toggle}>
        {playing ? 'Pause Background Music' : 'Play Background Music'}
      </span>
    </div>
  );
};

export default Player;
