import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import id from '../components/helpers/id';

import '../styles/components/faq.scss';

const Faq = ({ props, children }) => {
  let idString;

  useEffect(() => {
    setItemId(`faq-${id()}`);
  }, []);

  const [itemOpen, setItemOpen] = useState(false);
  const [itemId, setItemId] = useState('');

  const toggleItemOpen = () => {
    return setItemOpen(!itemOpen);
  };

  return (
    <div className="mt-6 space-y-6 divide-y divide-gray-200 faq__item">
      <button
        type="button"
        className="pt-6 text-lg text-left w-full flex justify-between items-start text-gray-400"
        aria-controls={itemId}
        aria-expanded={itemOpen}
        onClick={toggleItemOpen}
      >
        <span className="font-medium text-gray-900">
          {children.filter((child) => {
            return child.props.className.includes('faq__question');
          })}
        </span>
        <span className="ml-6 h-7 flex items-center">
          <svg
            className="rotate-0 h-6 w-6 transform"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </span>
      </button>
      <p
        aria-hidden={!itemOpen}
        id={itemId}
        className="mt-2 pt-6 pr-12 text-base text-gray-500 faq__answer__wrap"
      >
        {children.filter((child) => {
          return child.props.className.includes('faq__answer');
        })}
      </p>
    </div>
  );
};

export default Faq;
