import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

// markup
const CurrentFeaturesPartial = () => {
  return (
    <div
      id="current-features"
      className="s-features relative bg-soft-blue py-16 sm:py-24 lg:py-32"
    >
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="relative flex items-center justify-center h-12 w-12 mb-4 rounded-md text-white mx-auto">
        </div>
        <span className="o-accent-line mx-auto"></span>
        <h2 className="mt-2 text-3xl font-display tracking-tight text-gray-900 sm:text-4xl">
          Current Features
        </h2>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          We’re big fans of user-friendly design. We’ll keep building, but here are a few things to get us all started.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <div className="pt-6">
              <div className="flow-root rounded-lg bg-white px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <StaticImage
                      src="../../../images/Flags.png"
                      alt="Illustration of five flags on a hill"
                    />
                  </div>
                  <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                    Diverse Contributions
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    Different voices, different strengths and talents. Learn from subject matter experts all around the globe.
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="flow-root rounded-lg bg-white px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <StaticImage
                      src="../../../images/HelpfulContentinSuitcase.png"
                      alt="Illustration of Neighbour Institute content in an open suitcase"
                    />
                  </div>
                  <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                    Helpful Content
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    Where do you need to go on learning journey? We can provide all sorts of resources to get you where you need to go.
                  </p>
                </div>
              </div>
            </div>
            <div className="pt-6">
              <div className="flow-root rounded-lg bg-white px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <StaticImage
                      src="../../../images/Plane.png"
                      alt="Illustration of a small plane"
                    />
                  </div>
                  <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                    Mobile Friendly
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    Stay on the go. The Neighbour Institute allows for easy access and availability on all devices. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentFeaturesPartial;
