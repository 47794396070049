import React, { useState, useEffect, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

import { UserContext } from '../lib/UserContext';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';

const poll = async ({ fn, validate, interval, maxAttempts }) => {
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    const result = await fn();
    attempts++;

    if (validate(result)) {
      return resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'));
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
};

const SignInPage = (props) => {
  const HTag = `h${props.headingLevel ? props.headingLevel : 1}`;

  const emailEl = useRef(null);
  const closeEl = useRef(null);
  return (
    <>
      <Helmet>
        <title>Neighbour Institute • Sign In</title>
      </Helmet>
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
          <UserContext.Consumer>
            {(context) => {
              return (
                <main>
                    {modal ? (
                    <Link ref={closeEl} to={closeTo}>
                      × Close
                    </Link>
                  ) : null}
                  <div
                    className={
                      ' w-full md:w-1/2 mx-auto border-2 border-gray-300 grid grid-flow-row auto-rows-min gap-4 p-4 rounded-xl py-10 bg-soft-blue ' +
                      props.className
                    }
                  >
                    {context && !context.user ? (
                      <>
                        <HTag className="text-lg text-center font-bold">Sign in</HTag>
                        <p className="text-gray-600 text-sm text-center login-text">
                          Enter your work email address and we'll send you a
                          one-click sign in link!
                        </p>
                        <p className="text-gray-600 text-sm text-center login-text">
                          Some content on Neighbour Institute is limited to Compassion's global partners and their employees.
                        </p>
                        <div>
                          <label
                            htmlFor="email-input"
                            className="block text-slate-700 text-sm mt-4 mb-2 pl-1"
                          >
                            Email address
                          </label>
                          <input
                            id="email-input"
                            type="text"
                            placeholder="Enter your work email address"
                            autoCorrect="off"
                            ref={emailEl}
                            className="w-full appearance-none border rounded py-2 px-3 text-slate-700"
                          />
                        </div>
                        <button
                          className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-lg	 text-white bg-primary hover:bg-indigo-700 md:py-2 md:text-lg md:px-6"
                          onClick={
                            context && context.user
                              ? null
                              : async () => {
                                  const result = await context
                                    .handleLogin(emailEl.current.value)
                                    .then(() => {
                                      modal ? closeEl.current.click() : null;
                                    });
                                  // .then(() => {
                                  //   // Poll for user object on context
                                  //   poll({
                                  //     fn: () => {},
                                  //     validate: () => {
                                  //       console.dir(context);
                                  //     },
                                  //     interval: 200,
                                  //     maxAttempts: 20,
                                  //   })
                                  //     .then(() => closeEl.current.click())
                                  //     .catch(() => console.dir(context));
                                  // });
                                }
                          }
                        >
                          Send Magic Link
                        </button>
                      </>
                    ) : (
                      () => {
                        return (
                          context && (
                            <>
                              <h1>Signed in</h1>
                              <p>
                                You're signed in as{' '}
                                <span className="font-bold">
                                  {context.user.email}
                                </span>
                              </p>
                              <button
                                className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-full text-white bg-primary hover:bg-indigo-700 md:py-2 md:text-lg md:px-6"
                                onClick={context.handleLogout}
                              >
                                Sign out
                              </button>
                            </>
                          )
                        );
                      }
                    )}
                  </div>
                </main>
              );
            }}
          </UserContext.Consumer>
        )}
      </ModalRoutingContext.Consumer>
    </>
  );
};

export default SignInPage;
